import { Component, createEffect, createSignal, JSX, onCleanup, onMount, ParentComponent, Show } from 'solid-js'
import { Portal } from 'solid-js/web'

export interface ModalProps {
	header: string
	buttonContent: JSX.Element
	onOpen?: () => void
}

const Modal: ParentComponent<ModalProps> = props => {
	const [show, setShow] = createSignal(false)

	const [modalRef, setModalRef] = createSignal<HTMLDivElement>()
	const [overlayRef, setOverlayRef] = createSignal<HTMLDivElement>()

	const open = (e: MouseEvent) => {
		e.preventDefault()
		e.stopImmediatePropagation()
		props.onOpen?.()
		setShow(true)
	}

	const closeOnEscape = (e: KeyboardEvent) => {
		if (e.key === 'Escape' && show()) {
			setShow(false)
		}
	}

	const checkClickOutside = (e: MouseEvent) => {
		if (show() && modalRef() && !modalRef()!.contains(e.target as Node)) {
			setShow(false)
		}
	}

	createEffect(() => {
		if (show()) {
			document.body.classList.add('modal-open')
		} else {
			document.body.classList.remove('modal-open')
		}
	})

	document.addEventListener('keydown', closeOnEscape)
	document.addEventListener('click', checkClickOutside)

	onCleanup(() => {
		document.removeEventListener('keydown', closeOnEscape)
		document.removeEventListener('click', checkClickOutside)
	})

	return (
		<>
			<button class="w-fit" type="button" onClick={open}>
				{props.buttonContent}
			</button>
			<Portal>
				<Show when={show()}>
					<div class="modal-overlay" ref={setOverlayRef} />
					<div class="modal" role="dialog" aria-modal={true} aria-label={props.header} ref={setModalRef}>
						<div class="modal-header">
							<h2>{props.header}</h2>
							<button type="button" onClick={() => setShow(false)} aria-label="Schließt Popup">
								<svg viewBox="0 0 24 24" aria-hidden="true">
									<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
								</svg>
							</button>
						</div>
						<div class="modal-content">{props.children}</div>
					</div>
				</Show>
			</Portal>
		</>
	)
}

export default Modal
