import { StopFinderResponse } from './efa-stopfinder'
import { EfaInfoResponse } from './efa-info-request'

const API_VERSION = '10.5.17.3'

export const fetchStations = (query: string): string => {
	return `https://www.bwegt.de/bwegt-efa/XML_STOPFINDER_REQUEST?coordOutputFormat=WGS84[dd.ddddd]&language=de&locationInfoActive=1&locationServerActive=1&name_sf=${query}&outputFormat=rapidJSON&sl3plusStopFinderMacro=trip&type_sf=any&version=${API_VERSION}`
}

export const fetchInfos = async (): Promise<EfaInfoResponse> => {
	const date = new Date()
	const response = await fetch(
		`https://www.bwegt.de/bwegt-efa/XML_ADDINFO_REQUEST?filterDatePublication=${date.toLocaleDateString('de', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		})}&filterInfoType=bannerInfo&filterInfoType=generalInfo&filterShowLineList=0&filterShowPlaceList=0&filterShowStopList=0&outputFormat=rapidJSON&serverInfo=1&sl3plusAddInfoMacro=1&version=${API_VERSION}`
	)

	const json = await response.json()

	return json as EfaInfoResponse
}

export type { StopFinderResponse, EfaInfoResponse }
