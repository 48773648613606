// @ts-nocheck

import flatpickr from 'flatpickr'

function initDatePicker() {
	var flatpickrdate = {
		firstDayOfWeek: 1,
		weekAbbreviation: 'KW',
		rangeSeparator: ' bis ',
		scrollTitle: 'Zum Ändern scrollen',
		toggleTitle: 'Zum Umschalten klicken',
		weekdays: {
			shorthand: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
			longhand: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
		},
		months: {
			shorthand: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
			longhand: [
				'Januar',
				'Februar',
				'März',
				'April',
				'Mai',
				'Juni',
				'Juli',
				'August',
				'September',
				'Oktober',
				'November',
				'Dezember',
			],
		},
	}

	flatpickr.localize(flatpickr.l10ns.de)

	if (document.getElementsByClassName('date-picker-range')[0]) {
		var startTime = document.querySelector('.j-date-picker--start').getAttribute('data-start')
		if (startTime == '') {
			startTime = 'today'
		}

		var startPicker = flatpickr('.j-date-picker--start', {
			altInput: true,
			altFormat: 'D, d.m.y',
			locale: flatpickrdate,
			defaultDate: startTime,
			minDate: 'today',
			allowInput: true,
			onValueUpdate: function (selectedDates, dateStr, instance) {
				if (this.selectedDates[0] > endPicker.selectedDates[0]) {
					endPicker.setDate(this.selectedDates)
				}
			},
			onDayCreate: function (dObj, dStr, fp, dayElem) {
				var day = dayElem.dateObj.getTime()
				var start = this.selectedDates[0].getTime()
				var end = endPicker ? endPicker.selectedDates[0].getTime() : null

				if (day == start) {
					dayElem.classList.add('start-date')
				}
				if (day > start && day < end) {
					dayElem.classList.add('range-date')
				}
				if (day == end) {
					dayElem.classList.add('end-date')
				}
			},
			onOpen: function (selectedDates, dateStr, instance) {
				this.redraw()
			},
		})

		var endTime = document.querySelector('.j-date-picker--end').getAttribute('data-end')
		if (endTime == '') {
			endTime = 'today'
		}

		var endPicker = flatpickr('.j-date-picker--end', {
			altInput: true,
			altFormat: 'D, d.m.y',
			locale: flatpickrdate,
			defaultDate: endTime,
			minDate: 'today',
			allowInput: true,
			onValueUpdate: function (selectedDates, dateStr, instance) {
				if (this.selectedDates[0] < startPicker.selectedDates[0]) {
					startPicker.setDate(this.selectedDates)
				}
			},
			onDayCreate: function (dObj, dStr, fp, dayElem) {
				var day = dayElem.dateObj.getTime()
				var start = startPicker ? startPicker.selectedDates[0].getTime() : null
				var end = this.selectedDates[0].getTime()

				if (day == start) {
					dayElem.classList.add('start-date')
				}
				if (day > start && day < end) {
					dayElem.classList.add('range-date')
				}
				if (day == end) {
					dayElem.classList.add('end-date')
				}
			},
			onOpen: function (selectedDates, dateStr, instance) {
				this.redraw()
			},
		})
	} else if (document.getElementsByClassName('date-picker')[0]) {
		flatpickr('.date-picker', {
			dateFormat: 'd.m.Y',
			altInput: true,
			altFormat: 'D, d.m.y',
			locale: flatpickrdate,
			defaultDate: 'today',
			minDate: 'today',
			allowInput: true,
		})
	}

	if (document.querySelectorAll('.date-picker.form-control')) {
		var datePickers = document.querySelectorAll('.date-picker.form-control')
		datePickers.forEach(picker => picker.setAttribute('aria-label', 'Date'))
	}
}

export default initDatePicker
