import { For } from 'solid-js'

const ModalSkeleton = () => {
	function randomIntFromInterval(min: number, max: number) {
		return Math.floor(Math.random() * (max - min + 1) + min)
	}

	return (
		<For each={[...Array(10)]}>
			{() => (
				<span
					class={'w-full animate-pulse rounded-full bg-brightgrey'}
					style={{ width: `${randomIntFromInterval(20, 100)}%` }}>
					&nbsp;
				</span>
			)}
		</For>
	)
}

export default ModalSkeleton
